
import { gsap } from '~/plugins/utils/gsap'

export default {
  name: 'SplitSlider',
  components: {
    DarkBackgroundWrapper: () => import('~/components/blocks/dark-bg-wrapper.vue')
  },
  data: () => ({
    activeIndex: 0,
    interval: null,
    viewport: gsap.matchMedia(),
    viewportWidth: '(min-width: 1024px)'
  }),
  props: {
    slides: {
      type: Object,
      required: true
    },
    folder: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      if (this.$refs.sliderImages) {
        clearInterval(this.interval)
        this.interval = null
        const images = this.$refs.sliderImages
        this.viewport.add(this.viewportWidth, () => {
          images.forEach((image, index) => {
            if (index !== this.activeIndex) {
              gsap.set(image, { xPercent: 101, autoAlpha: 0 })
            }
          })
          this.startSlider()
        })
      }
    }, 50)
  },
  watch: {
    activeIndex (curr, prev) {
      this.viewport.add(this.viewportWidth, () => {
        gsap.set(this.$refs.sliderImages[curr], { xPercent: 0, autoAlpha: 1 })
        gsap.set(this.$refs.sliderImages[prev], { xPercent: 101, autoAlpha: 0 })
      })
    }
  },
  computed: {
    folderBase () {
      return `/upload/pages/crm-for-business/slugs/${this.folder}/split-slider/`
    }
  },
  methods: {
    startSlider () {
      this.interval = setInterval(() => {
        this.activeIndex = (this.activeIndex + 1) % this.slides.items.length
      }, 10000)
    },
    clickAction (index) {
      if (!this.slides.useMobileImage) {
        this.viewport.add(this.viewportWidth, () => {
          clearInterval(this.interval)
          this.interval = null
          this.startSlider()
        })
      }
      this.activeIndex = index
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.interval = null
  }
}
