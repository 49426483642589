
import { gsap } from '~/plugins/utils/gsap'
import RegistrationLocal from '~/mixins/register-local'
import { pages } from '~/plugins/json/clients/meta.json'

export default {
  name: 'CrmForBusinessTemplateUpdated',
  components: {
    Companies: () => import('~/components/blocks/companies.vue'),
    SplitSlider: () => import('./split-slider.vue'),
    SplitScrollingBlock: () => import('~/components/blocks/split-scrolling-block.vue'),
    Prefooter: () => import('~/components/elements/pre-footer.vue'),
    SliderMultipleItems: () => import('~/components/UI/slider-multiple-items.vue'),
    ClientCard: () => import('~/components/pages/clients/client-card.vue'),
    VideoCases: () => import('~/components/UI/video-cases.vue'),
    DarkBackgroundWrapper: () => import('~/components/blocks/dark-bg-wrapper.vue'),
    StagesBlock: () => import('~/components/blocks/stages-block.vue'),
    ImplementationBlock: () => import('~/components/blocks/implementation-block.vue'),
    FaqBlock: () => import('~/components/blocks/faq-block.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue')
  },
  mixins: [RegistrationLocal],
  data () {
    return {
      basePath: '/upload/pages/crm-for-business/',
      solutionsList: this.$t('pages.crm-for-business-slug.solutions.items'),
      excludeConsultationBlock: ['crm-for-advertising-production']
    }
  },
  mounted () {
    if (this.excludeConsultationBlock.includes(this.meta.slug)) {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.crm-industry__solutions',
          start: 'top top'
        }
      }).from('.table__item', {
        lazy: true,
        ease: 'power2.inOut',
        xPercent: -100,
        yPercent: -50,
        scale: 0.2,
        opacity: 0,
        stagger: 0.5
      })
    }
  },
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  methods: {
    contactUsClick () {
      this.showPopup('form', {
        header: this.$t('regForm.contactSales'),
        formEmailName: 'default',
        formEmailTitle: `${this.$t('regForm.requestFrom')} "${document.title}"`
      })
    }
  },
  computed: {
    clientsCases () {
      return pages.filter((el) => this.meta.clients.includes(el.slug))
    },
    folder () {
      return `${this.basePath}slugs/${this.meta.slug}`
    }
  }
}
